import moment from "moment"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useForm } from "react-form"
import { MdHelpOutline } from "react-icons/md"
import useSuggestedKeywords from "../../../../../hooks/use-suggested-keywords"
import usePeople from "../../../../../hooks/use-people-tags"
import CollapsibleSection from "../../../../common/collapsible-section/collapsible-section"
import Checkbox from "../../../../common/form/check-box/check-box"
import TextArea from "../../../../common/form/text-area/text-area"
import SelectBox from "../../../../common/form/select-box/select-box"
import TextField from "../../../../common/form/text-field/text-field"
import YearMonth from "../../../../common/form/year-month/year-month"
import Button from "../../../../common/button/button"
import SuggestedKeywords from "../../../../common/suggested-keywords/suggested-keywords"
import AutocompleteTags from "../../../../search/inputs/autocomplete-tags/autocomplete-tags"
import styles from "./media-individual-details.module.scss"
import _ from "lodash"
import ModalWindow from "../../../../common/modal-window/modal-window"
import { getGroups } from "../../../../../services/group"
import auth from "../../../../../services/auth"
import CreateMedia from "../../../../create-group"

function MediaIndividualDetails({
  idx,
  formWrapper,
  collectionKeywords,
  validateFn,
  fnUpdateGroups,
  updatedGroups
}) {
  const [suggestedKeywords, updateKeywordsForSuggested] = useSuggestedKeywords()
  const [peopleTags, updatePeople] = usePeople()
  const [group, setGroup] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [groupItems, setGroupItems] = useState([])
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [groupWord, setGroupWord] = useState([])
  const [groupsSelected, setGroupsSelected] = useState([])
  const [allGroups, setAllGroups] = useState([])
  const todayMonthYear = moment(new Date())
    .format("YYYYMMDD")
    .toString()

  const defaultValues = React.useMemo(
    () => ({
      title: null,
      description: null,
      photoDate: "",
      photoNumber: null,
      sourceNumber: null,
      catalogueNumber: null,
      description: null,
      people: null,
      isDateUnknown: false,
      keywords: [],
      groups: [],
    }),
    []
  )

  formWrapper.formInstance = useForm({
    defaultValues: defaultValues,

    validate: (v, i) => {
      return validateFn(v, i)
    },
    validatePristine: true,
  })

  useEffect(() => {
    let all = []  
    getGroups().then(groups => {

      setGroupItems(groups)
      _.map(groups, (group) => {
        const selectGroup = {
          label: group.title,
          value: group.title,
          ...group
        }   

        all.push(selectGroup)
      })

      setAllGroups(all)
    })

    if (auth.isBrowser()) 
      window.scrollTo(0, 0)
  }, [])

  if (updatedGroups.length !== allGroups.length && updatedGroups.length > 0) {
    console.log('updatedGroups:', updatedGroups)

    let all = []
    _.map(updatedGroups, (group) => {
      const selectGroup = {
        label: group.title,
        value: group.title,
        ...group
      }   

      all.push(selectGroup)
    })
    setAllGroups(all)
  }

  const handleChangeGroup = (value) => {
      setGroupsSelected(value)
  }  

  const { Form, pushFieldValue } = formWrapper.formInstance

  const addSuggested = k => {
    const newKeyword = { value: k.term, source: { _id: k._id } }
    pushFieldValue("keywords", newKeyword)
  }

  const formValues = formWrapper.formInstance.values
  if (formValues.isDateUnknown) {
    formValues.photoDate = ""
  }

  const GroupItem = checkedItems => {
    setCheckedItems(checkedItems)
    formValues.groups = checkedItems
  }

  formValues.groups = groupsSelected

  const getUpdatedGroups = () => {
      getGroups().then(groupWord => {
        // console.log("groupWord:", groupWord)
        // setAllGroups(groupWord)
        fnUpdateGroups(groupWord)
      })
  }

  const CreateGroup = () => {
    setCreateModalOpen(true)
  }

  const setAddedGroup = (addedGroup) => {
    const added = {
      ...addedGroup,
      label: addedGroup.title,
      value: addedGroup.title
    }

    const selected = [
      ...groupsSelected,
      added
    ]

    setGroupsSelected(selected)

    const all = [
      ...allGroups,
      added
    ]
    setAllGroups(all)   
  }

  const getModalWindow = () => {
    const onModalClose = () => {
      setCreateModalOpen(false)
    }
    if (createModalOpen) {
      return (
        <ModalWindow title="Create Group" closeHandler={onModalClose}>
          <CreateMedia
            updatedGroups={getUpdatedGroups}
            setCreateModalOpen={setCreateModalOpen}
            setGroup={setGroup}
            group={group}
            setCheckedItems={GroupItem}
            setGroupWord={setGroupWord}
            setAddedGroup={setAddedGroup}
          />
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  return (
    <div>
      <Form
        className={formWrapper.media.active ? styles.active : styles.inactive}
      >
        <div className={styles.fields}>
          <div className={styles.fieldsGroup}>
            <h4>Media Details</h4>
            <TextField
              id={"title" + (idx !== undefined ? idx : "")}
              label="Title"
              field="title"
              defaultValue={group}
              validate={value => {
                return !value ? "This is required!" : false
              }}
              mandatory
            />
            <TextArea
              id={"description" + (idx !== undefined ? idx : "")}
              label="Description"
              field="description"
              validate={value => {
                return !value ? "This is required!" : false
              }}
              mandatory
            />

            <div className={styles.fieldsGroup}>
              <h4>Tags
                <span
                  className={styles.helpIcon}
                  data-tip="Adding additional keywords will help other find your image during searches."
                  data-event="click focus"
                >
                  <a onClick={e => e.preventDefault()}>
                    <MdHelpOutline />
                  </a>
                </span>                
              </h4>
              <div className={styles.keywordWrap}>
                <AutocompleteTags
                  index="keyword"
                  dataField="term"
                  field="keywords"
                  label="Tags"
                  defaultTags={collectionKeywords}
                  defaultValue={[]}
                  validate={tags => {
                    updateKeywordsForSuggested(
                      _.union(tags, collectionKeywords)
                    )
                    return !tags || !tags.length ? "This is required!" : false
                  }}
                  mandatory
                />

                <h5>
                  Suggested Tags
                </h5>
                <SuggestedKeywords
                  words={suggestedKeywords}
                  add={addSuggested}
                />
              </div>
            </div>

            <YearMonth
              showDay={true}
              label="Date"
              field="photoDate"
              dateUnknown={formValues.isDateUnknown}
              disabled={formValues.isDateUnknown}
              validate={value => {
                return !value && !formValues.isDateUnknown
                  ? "This is required!"
                  : false             
                }}
              mandatory={!formValues.isDateUnknown}
            />
            <Checkbox
              label="Date is Approximate"
              field="isDateApproximate"
              className={styles.approximate}
              isDisabled={formValues.isDateUnknown}
            />
            <Checkbox
              label="Unknown Date"
              field="isDateUnknown"
              className={styles.approximate}
            />
            {/* <TextField label="People" field="people" /> */}

            {process.env.GATSBY_USE_FHAA === true ||
            process.env.GATSBY_USE_FHAA === "true" ? (
              <CollapsibleSection>
                <TextField label="Photo Number" field="photoNumber" />
                <TextField label="Source Number" field="sourceNumber" />
                <TextField label="Catalogue Number" field="catalogueNumber" />
                <TextArea label="Note" field="description" />
              </CollapsibleSection>
            ) : (
              <TextArea label="Note" field="description" />
            )}
          </div>

          <h4>
            Add to Group
            <span
              styles={{ cursor: "pointor" }}
              className={styles.helpIcon}
              data-tip="Please click CREATE to create a new group."
              data-event="click focus"
            >
              <a onClick={e => e.preventDefault()}>
                <MdHelpOutline />
              </a>
            </span>
          </h4>
          <div style={{ display: "flex" }}>
            <SelectBox
                field="groups"
                options={allGroups}
                value={groupsSelected}
                onChange={handleChangeGroup}
                defaultValue={'Group'}
                isMulti
            />
            {getModalWindow()}
            <span style={{ marginLeft: "5px" }}>
              {
                <Button
                  id="add-group"
                  label="New Group"
                  type="button"
                  btnStyle="primary"
                  btnSize="multipleWords"
                  onClick={CreateGroup}
                />
              }
            </span>
          </div>

          <div className={styles.fieldsGroup}>
            <h4>Tag People</h4>
            <div className={styles.keywordWrap}>
              <AutocompleteTags
                index="people"
                dataField="name"
                field="peopleTags"
                label="People"
                defaultValue={[]}
                validate={tags => {
                  updatePeople(tags)
                }}
              />
              <br />
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

MediaIndividualDetails.propTypes = {
  idx: PropTypes.number,
  formWrapper: PropTypes.object,
  collectionKeywords: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  goBack: PropTypes.func,
}

export default MediaIndividualDetails
