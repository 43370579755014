import { navigate } from "@reach/router"
import axios from "axios"
import classNames from "classnames"
import React, { useContext, useEffect, useState } from "react"
import { useBeforeunload } from "react-beforeunload"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import { UploadStepContext } from "../../common/upload-step-provider/upload-step-provider"
import StepBar from "../step-bar/step-bar"
import ChooseMediaType from "../steps/01-choose-media-type/choose-media-type"
import UploadMedia from "../steps/02-upload-media/upload-media"
import CollectionInformation from "../steps/03-collection-information/collection-information"
import CollectionWrapper from "../steps/04-media-details/collection-wrapper/collection-wrapper"
import styles from "./upload-wizard.module.scss"
import mediaService, { createMedia, createImageHash } from "../../../services/media"
import keywordService from "../../../services/keyword"
import peopleService from "../../../services/people"
import groupService from "../../../services/group"
import modal from "../../../services/modal"
import { LocationCity } from "@mui/icons-material"
import _ from "lodash"

const steps = [
  // { name: "Choose Media Type", component: ChooseMediaType },
  { name: "Upload Media", component: UploadMedia },
  {
    name: "Collection Details",
    component: CollectionInformation,
    isFlex: true,
  },
  { name: "Media Details", component: CollectionWrapper, isFlex: true },
]

const UploadWizard = ({ constants}) => {
  const stepCtx = useContext(UploadStepContext)
  const [curFormData, setCurFormData] = useState({})

  const [formWrappers, setFormWrappers] = useState([])

  useEffect(() => {
    console.log("step is: ", stepCtx.getStep())
    if (stepCtx.getStep() === steps.length) {
      createMediaItem()
    }
  }, [stepCtx.getStep()])

  useBeforeunload(event => {
    confirmNav(event)
  })

  const confirmNav = event => {
    if (stepCtx && stepCtx.getStep() > 0) {
      event.preventDefault()
      return "Any unsaved progess will be lost. Do you wish to continue?"
    }
  }

  /**
   *
   * @param {boolean} showConfirm defaults to true because data will be lost in most cases
   */
  const cancel = showConfirm => {
    if (showConfirm === false) {
      navigate("/")
    } else {
      onShowConfirm(() => {
        navigate("/")
      })
    }
  }
  

  const onShowConfirm = onConfirmed => {
    modal.setAlertModalOpen(true, {
      title: "Any unsaved progess will be lost.",
      content: "Do you want to continue?",
      confirm: onConfirmed,
      confirmLabel: "Yes",
    })
  }

  /**
   *
   * @param {boolean} showConfirm defaults to false because no data will be lost in most cases
   */
  const goBack = showConfirm => {
    if (!showConfirm) {
      stepCtx.setStep(s => s - 1)
    } else {
      onShowConfirm(() => {
        stepCtx.setStep(s => s - 1)
      })
    }
  }

  const createMediaItem = async () => {

    var mediasToSave = []
    _.forEach(formWrappers, function(wrapper) {
      let newMedia = {
        type: wrapper.media.mediaType,
        rightsAck: true,
        location: curFormData.location,
        forest: curFormData.forest,
        permissions: curFormData.permissions,
        copyright: curFormData.copyright,
        groups: curFormData.groups,
        source_file: { ...wrapper.media },
        geo: curFormData.geo
      }
      _.extend(newMedia, wrapper.formInstance.values)
      newMedia.keywords = keywordService.getKeywordsForSave([
        wrapper.formInstance.values,
        curFormData,
      ])
      newMedia.peopleTags = peopleService.getPeopleForSave([
        wrapper.formInstance.values,
        curFormData,
      ])

      console.log('NewMedia:', newMedia)
      mediasToSave.push(newMedia)
    })
    delete curFormData.medias
    delete curFormData.collectionKeywords

    var suffix = mediasToSave.length > 1 ? (suffix = "s") : ""

    const newItems = await mediaService.createMedia(mediasToSave)

    console.log("mediasToSave: ", mediasToSave)

    let message
    switch (curFormData.type) {
      case "Archive":
        message = "Archive" + suffix + " Created!"
        break
      case "Audio":
        message = "Audio" + suffix + " Created!"
        break
      case "Document":
        message = "Document" + suffix + " Created!"
        break
      case "Photo":
        message = "Photo" + suffix + " Created!"
        break
      case "Video":
        message = "Video" + suffix + " Created!"
        break
    }
    await navigate("/view-my-media/")
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    })
  }

  const handleStepSubmit = d => {
    console.log("handleStepSubmit=>>", d)
    setCurFormData(old => {
      return {
        ...old,
        ...d,
      }
    })
    stepCtx.setStep(s => s + 1)
  }

  return (
    <>
      <div className={styles.layout}>
        <div className={styles.top}></div>
        <div className={styles.wrap}>
          {/* <div className={styles.bar}>
            <StepBar steps={steps} curStep={stepCtx.getStep()} />
          </div> */}
          <div className={styles.stepsWrap}>
            {steps.map((step, i) =>
              stepCtx.getStep() >= i ? (
                <step.component
                  className={classNames(styles.step, {
                    [styles.active]: stepCtx.getStep() === i,
                    [styles.flex]: step.isFlex,
                  })}
                  formData={curFormData}
                  submit={handleStepSubmit}
                  cancel={cancel}
                  goBack={goBack}
                  formWrappers={formWrappers}
                  key={step.name}
                  constants={constants}
                />
              ) : (
                ""
              )
            )}
          </div>
        </div>
      </div>
      <ReactTooltip
        place="bottom"
        type="info"
        effect="solid"
        globalEventOff="click"
      />
    </>
  )
}

export default UploadWizard
