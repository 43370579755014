import React, { useState } from "react"
import PropTypes from "prop-types"
import styles from "./collapsible-section.module.scss"
import { MdAddCircle } from "react-icons/md"
import classNames from "classnames"

function CollapsibleSection({ label, id, className, children }) {
  const [moreDetailsToggle, setMoreDetailsToggle] = useState(false)

  return (
    <>
      <a
        id={id}
        className={classNames(styles.wrapper, className)}
        onClick={e => {
          setMoreDetailsToggle(t => !t)
          e.preventDefault()
        }}
      >
        {label || "More Details"} <MdAddCircle />
      </a>
      <div
        className={classNames({
          [styles.show]: moreDetailsToggle,
          [styles.hide]: !moreDetailsToggle,
        })}
      >
        {children}
      </div>
    </>
  )
}

CollapsibleSection.defaultProps = {
  id: "moreDetails",
}

CollapsibleSection.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
}

export default CollapsibleSection
