import React, { useState } from "react"
import UploadLayout from "../../components/layout/upload-layout/upload-layout"
import "./index.scss"
import UploadWizard from "../../components/upload/upload-wizard/upload-wizard"
import constants from "../../services/constants_afpa";


const Upload = () => {
  return (
    <UploadLayout step={0}>
      <UploadWizard constants={constants} />
    </UploadLayout>
  )
}

export default Upload
