import React, { useState } from "react"
import PropTypes from "prop-types"
import Checkbox from "../../../common/form/check-box/check-box"
import styles from "./upload-media.module.scss"
import { useForm } from "react-form"
import FileUpload from "../../../common/form/file-upload/file-upload"
import classNames from "classnames"
import Button from "../../../common/button/button"



const UploadMedia = ({ formData, submit, className, goBack }) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [location, setLocation] = useState()

  const fileField = "files"
  const defaultValues = React.useMemo(
    () => ({
      files: [],
    }),
    []
  )

  const formInstance = useForm({
    defaultValues,
    debugForm: false,
    onSubmit: values => {
      submit({
        rightsAck: formData.rightsAck,
        medias: values[fileField],
        geo: location,
        type: values.files[0].mediaType,
      })
    },
    validate: (values, instance) => {
      setSubmitAttempted(false)
      if (!values.rightsAck) {
        return "You must acknowledge that you have permission to publish and share this media."
      }

      if (!values.files || values.files.length === 0) {
        return "You must select files to upload"
      }
      return false
    },
  })
  const { Form, meta } = formInstance

  const onCancelClick = event => {
    // show confirm message if there are files
    goBack(formInstance.values.files && formInstance.values.files.length > 0)
  }

  const onNextClick = () => {
    setSubmitAttempted(true)
    console.log("next button clicked")
  }

  return (
    <Form className={classNames(styles.uploader, className)}>
      <FileUpload
        field={fileField}
        mediaType={formData.type}
        // accept="image/*,video/*,audio/*,.pdf,.docx"
        fnLocation={setLocation}
        callSetLocation={true}
        mandatory
        validate={value => {
          return !value || value.length < 1 ? "This is required!" : false
        }}
      />
      <div className={styles.acceptablefiletypes}>
          Acceptable file types: PNG, JPG, TIFF, MP3, MP4, MOV, PDF, docx
      </div>
      <div className={styles.permissionCheck}>
        <Checkbox
          field="rightsAck"
          label="I acknowledge that I have permission to publish and share this media"
          validate={value => {
            return !value ? "This is required!" : false
          }}
          mandatory
          errorMessage={submitAttempted ? meta.error : ""}
        />
      </div>

      <br />
      <div className={styles.buttonWrap}>
        <Button
          id="upload-media-cancel"
          type="button"
          label="Cancel"
          btnStyle="ghost"
          btnSize="large"
          onClick={onCancelClick}
        />
        <Button
          id="upload-media-next"
          type="submit"
          label="Next"
          btnStyle="primary"
          btnSize="large"
          onClick={onNextClick}
        />
      </div>
    </Form>
  )
}

UploadMedia.propTypes = {
  formData: PropTypes.object,
  submit: PropTypes.func.isRequired,
  className: PropTypes.string,
  cancel: PropTypes.func,
  filesDisplay: PropTypes.func,
}

export default UploadMedia
