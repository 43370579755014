/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { ToastContainer } from "react-toastify"
import UploadProvider from "../../common/upload-step-provider/upload-step-provider"
import Header from "../header/header"
import "./upload-layout.scss"
import ModalWrapper from "../../common/modal/modal-wrapper/modal-wrapper"
import ReactTooltip from "react-tooltip"

const UploadLayout = ({ children, step }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleUploadQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <UploadProvider step={step}>
      {/* Every layout needs this to be able to display reusable modal windows (e.g. alertModal) */}
      <ModalWrapper />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main> {children} </main>
      <ToastContainer />
    </UploadProvider>
  )
}

UploadLayout.propTypes = {
  children: PropTypes.node.isRequired,
  upload: PropTypes.object,
}

export default UploadLayout
