import classNames from "classnames"
import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { MdCancel, MdCheckCircle } from "react-icons/md"
import Thumbnail from "../../common/display/thumbnail/thumbnail"
import FileUpload from "../../common/form/file-upload/file-upload"
import styles from "./collection-media-display.module.scss"

const CollectionMediaDisplay = ({
  id,
  medias,
  mediaType,
  activeMedia,
  setActiveFn,
  handleRemoveFn,
  handleAddFn,
  formWrappers,
}) => {
  console.log("collectionMediaDisplay:", medias)
  console.log("styles.active: " + styles)

  const onMediaClick = (event, media) => {
    if (setActiveFn) {
      event.stopPropagation()
      setActiveFn(media)
    }
  }

  const onRemoveClick = (event, media) => {
    event.stopPropagation()
    handleRemoveFn(media)
  }

  const handleFilesUploaded = files => {
    handleAddFn(files)
  }

  const isAddRemoveHidden = () => {
    return formWrappers && formWrappers.length > 0
  }

  const isCheckHidden = (mediaData, icon) => {
    // we don't show a Check if media is the active one or if not enough information
    var isActiveMedia = activeMedia && mediaData._id === activeMedia._id
    if (isActiveMedia || !formWrappers || formWrappers.length === 0) {
      return true
    }
    var wrapper = _.find(formWrappers, { id: mediaData._id })
    if (wrapper && wrapper.formInstance) {
      // form is invalid so hide check
      return (
        !wrapper.formInstance.meta.isValid ||
        !wrapper.formInstance.meta.isTouched
      )
    } else {
      return true
    }
  }

  return (
    <ul id={id ? id : "collectionMediaList"} className={styles.thumbnailBatch}>
      {isAddRemoveHidden() ? (
        ""
      ) : (
        <FileUpload
          field="additionalFiles"
          mediaType={mediaType}
          format="compact" // This returns a smaller version in a <li></li>
          hidden={isAddRemoveHidden()}
          key="addMedia"
          onFileChange={handleFilesUploaded}
          callSetLocation={false}
        ></FileUpload>
      )}

      {_.map(medias, (mediaData, idx) => (
        <li
          key={mediaData._id}
          onClick={event => {
            onMediaClick(event, mediaData)
          }}
          className={
            activeMedia && mediaData._id === activeMedia._id
              ? styles.active
              : ""
          }
        >
          <MdCheckCircle
            className={classNames({
              [styles.checkIcon]: true,
              [styles.hidden]: isCheckHidden(mediaData),
            })}
          />
          <MdCancel
            id={(id ? id : "collectionMediaList") + "-" + idx + "-remove"}
            onClick={event => {
              onRemoveClick(event, mediaData)
            }}
            className={classNames({
              [styles.closeIcon]: true,
              [styles.hidden]: isAddRemoveHidden(),
            })}
          />
          <span className={styles.thumbnailMask}>
            <Thumbnail data={mediaData} idx={idx}/>
          </span>
        </li>
      ))}
    </ul>
  )
}

CollectionMediaDisplay.propTypes = {
  id: PropTypes.string,
  medias: PropTypes.array.isRequired,
  className: PropTypes.string,
  activeMedia: PropTypes.object,
  setActiveFn: PropTypes.func,
  handleRemoveFn: PropTypes.func,
  handleAddFn: PropTypes.func,
  formWrappers: PropTypes.array,
}

export default CollectionMediaDisplay
