import classNames from "classnames"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useForm } from "react-form"
import { MdHelpOutline } from "react-icons/md"
import ReactTooltip from "react-tooltip"
import useSuggestedKeywords from "../../../../hooks/use-suggested-keywords"
import Button from "../../../common/button/button"
import ModalWindow from "../../../common/modal-window/modal-window"
import MediaMapping from "../../../media-mapping/media-mapping"
import CollapsibleSection from "../../../common/collapsible-section/collapsible-section"
import Webfile from "../../../common/display/webfile/webfile"
import RadioButtonList from "../../../common/form/radio-button-list/radio-button-list"
import TextField from "../../../common/form/text-field/text-field"
import SuggestedKeywords from "../../../common/suggested-keywords/suggested-keywords"
import CollectionMediaDisplay from "../../../media-output/collection-media-display/collection-media-display"
import AutocompleteTags from "../../../search/inputs/autocomplete-tags/autocomplete-tags"
import Autocomplete from "../../../search/inputs/autocomplete/autocomplete"
import styles from "./collection-information.module.scss"
import auth from "../../../../services/auth"

function CollectionInformation({
  formData,
  submit,
  cancel,
  className,
  constants,
}) {

  const authObj = auth.getAuth()

  let org = ''
  if (authObj.user.organization) {
    org = authObj.user.organization.name ? authObj.user.organization.name : 'my organization'
  }
  else {
    org = 'my organization'
  }   


  const permissionOpts = constants.getPermissionOptions(org)
  const copyrightOpts = constants.getCopyrightOptions()
  const [suggestedKeywords, updateKeywords] = useSuggestedKeywords()
  const [submitAttempted, setSubmitAttempted] = useState()
  const [mapModal, setMapModal] = useState(false)
  const [geo, setGeo] = useState(formData.geo)
  const [geoExif, setGeoExif] = useState(formData.geo)
  const [medias, setMedias] = useState(formData.medias ? formData.medias : [])
  const defaultValues = React.useMemo(() => {
    return { location: { value: "" } }
  }, [])

  const { Form, meta, pushFieldValue } = useForm({
    defaultValues: defaultValues,
    debugForm: false,
    onSubmit: values => {
      const medias = []
      if (formData.medias) {
        let firstMedia = true
        _.forEach(formData.medias, (mediaData) => {
          if (firstMedia) {
            mediaData.active = true
            firstMedia = false
          }
          else {
            mediaData.active = false
          }
          medias.push(mediaData)
        })
      }

      let lat = 0
      let lon = 0
      let location = ''
      if (geo.lat !== '') {
        lat = geo.lat
        lon = geo.lon
      }


      if (geo.name !== '') {
        location = geo.name
      }

      submit({
        ...values,
        medias,
        location,
        geo: geo.name !== '' ? {lat, lon} : null,        
        forest: values.forest ? values.forest.value : null,
        collectionKeywords: values.collectionKeywords,
      })},
    validate: (values, instance) => {
      setSubmitAttempted(false)
      // if (!values.location) {
      //   return "Location is required."
      // }

      // if (!values.permissions) {
      //   return "Sharing permission is required."
      // }

      // if (!values.copyright) {
      //   return "Copyright permission is required."
      // }

      return false
    },
  })
  console.log("formData is: ", formData)

  const onMediaRemove = media => {
    formData.medias = _.union([], _.pull(formData.medias, media))
    setMedias(formData.medias)
  }

  const onMediaAdd = files => {
    var addedMedias = []
    _.map(files, function(file) {
      addedMedias.push(file)
    })

    formData.medias = _.union(formData.medias, addedMedias)
    setMedias(formData.medias)
  }

  const addSuggested = k => {
    const newKeyword = { value: k.term, source: { _id: k._id } }
    pushFieldValue("collectionKeywords", newKeyword)
  }

  const onNextClick = () => {
    console.log("collection information - next button clicked")
    setSubmitAttempted(true)
  }

  const onMapClick = () => {
    setMapModal(true)

    // addMappingMarker(geo)
  }

  const setLocationCoordinates = (location) => {
    
    const obj = {
      name: location.name,
      lat: location.geo.lat,
      lon: location.geo.lon
    }

    setGeo(obj)
  }

  const onClearClick = () => {
    setGeo({lat: 55.000000, lon: -115.000000, name: ''})
  }

  const getMapModal = () => {
    const onMapClose = () => {
      setMapModal(false)
    }

    if (mapModal) {
      return (
        <ModalWindow title="Map Position" closeHandler={onMapClose}>
          <MediaMapping 
            setLocation={setLocationCoordinates} 
            setMapModal={setMapModal}
            onMapClose={onMapClose}
            location={geo.name}
            geo={geo}
          />
        </ModalWindow>
      )
    }
  }

  const getKeywords = () => {
    ReactTooltip.rebuild()

    return (
      <div className={styles.fieldsGroup}>
        <h4>Collection Keywords
          <span
            className={styles.helpIcon}
            data-tip="Adding additional keywords will help others find your image during searches."
            data-event="click focus"
          >
            <a onClick={e => e.preventDefault()}>
              <MdHelpOutline />
            </a>
          </span>          
        </h4>
        <div className={styles.keywordWrap}>
          <AutocompleteTags
            index="keyword"
            dataField="term"
            field="collectionKeywords"
            // label="Preferred Term"
            defaultValue={[]}
            validate={tags => {
              updateKeywords(tags)
              return false
            }}
            // mandatory
          />

          <h5>
            Suggested Keywords
          </h5>
          <SuggestedKeywords words={suggestedKeywords} add={addSuggested} />
        </div>
      </div>
    )
  }

  const getMediaPreview = () => {
    if (!medias) {
      return <div>Error: no valid media</div>
    } else if (medias.length === 1) {
      return (
        <div className={styles.singlePreview}>
          <Webfile item={medias[0]} type={medias[0].mediaType} />
        </div>
      )
    } else {
      return (
        <CollectionMediaDisplay
          id={"CollectionMediaListStep03"}
          medias={medias}
          mediaType={formData.type}
          handleRemoveFn={onMediaRemove}
          handleAddFn={onMediaAdd}
        ></CollectionMediaDisplay>
      )
    }
  }

  return (
    <div className={classNames(styles.wrapper, className)}>
      <Form>
        <div className={styles.content}>
          <div className={styles.thumbnailWrap}>{getMediaPreview()}</div>
          <div className={styles.fieldWrap}>
            <div className={styles.fieldsGroup}>
              <h4>
                Collection Details
                <span
                  className={styles.helpIcon}
                  data-tip="The collection this media belongs to"
                  data-event="click focus"
                >
                  <a onClick={e => e.preventDefault()}>
                    <MdHelpOutline />
                  </a>
                </span>
              </h4>
              <TextField
                label="Collection Name"
                field="collectionName"
                type="text"
                //description="The collection this media belongs to"
              />
              {/* <Autocomplete
                label="Location"
                index="location"
                field="location"
                dataField="name"
                icon="MdPlace"
                allowMultiple={false}
                mandatory
                validate={value => {
                  return !value || !value.value ? "This is required!" : false
                }}
              /> */}
              <div className={styles.locationSection}>
                <div className={styles.locationItem}>
                  {'Location: '}
                  <b>{geo.name ? geo.name : 'No Location'}</b>
                </div>
                <div className={styles.locationItem}>
                  <Button
                    id="map-button"
                    type="button"
                    btnStyle="primary"
                    btnSize="small"
                    prependIcon="MdGpsFixed"
                    onClick={onMapClick}
                  />
                </div>  
                <div className={styles.locationItem}>  
                  <Button
                    id="clear-button"
                    type="button"
                    btnStyle="primary"
                    btnSize="small"
                    prependIcon="MdDeleteForever"
                    onClick={onClearClick}
                  />
                </div>
              </div>
              {getMapModal()}
              <br />
              {process.env.GATSBY_USE_FHAA === true ||
              process.env.GATSBY_USE_FHAA === "true" ? (
                <>
                  <TextField
                    label="Credit"
                    field="credit"
                    type="text"
                    description="The person who provides this media."
                  />
                  <TextField label="Source" field="source" type="text" />
                </>
              ) : (
                <TextField
                  label="Photographer"
                  field="photographer"
                  type="text"
                />
              )}

              {process.env.GATSBY_USE_FHAA === true ||
              process.env.GATSBY_USE_FHAA === "true" ? (
                <CollapsibleSection>
                  <TextField label="Province" field="province" type="text" />
                  {/* <TextField label="Forest" field="forest" type="text" /> */}
                  <Autocomplete
                    label="Forest"
                    index="forest"
                    field="forest"
                    dataField="name"
                    icon="MdSatellite"
                    allowMultiple={false}
                  />
                  <TextField
                    label="Photographer"
                    field="photographer"
                    type="text"
                  />
                </CollapsibleSection>
              ) : (
                ""
              )}
            </div>

            {getKeywords()}
            <div className={styles.fieldsGroup}>
              <h4>Permissions &amp; Copyright</h4>

              <div className={styles.permissionGroup}>
                <h5>
                  Sharing<span className={styles.star}>*</span>
                </h5>
                <RadioButtonList
                  field="permissions"
                  options={permissionOpts}
                  validate={value => {
                    return !value ? "This is required!" : false
                  }}
                />
              </div>

              <div className={styles.permissionGroup}>
                <h5>
                  Copyright
                  <span className={styles.star}>*</span>
                  <span
                    className={styles.helpIcon}
                    data-tip="Choose a copyright option that works best for you and your organization."
                    data-event="click focus"
                  >
                    <a onClick={e => e.preventDefault()}>
                      <MdHelpOutline />
                    </a>
                  </span>
                </h5>
                <RadioButtonList
                  field="copyright"
                  options={copyrightOpts}
                  validate={value => {
                    return !value ? "This is required!" : false
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.action}>
          <div className={styles.buttonWrap}>
            <Button
              id="upload-collection-cancel"
              type="button"
              label="Cancel"
              btnStyle="ghost"
              btnSize="large"
              onClick={cancel}
            />
            <Button
              id="upload-collection-next"
              type="submit"
              label="Next"
              btnStyle="primary"
              btnSize="large"
              onClick={onNextClick}
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

CollectionInformation.propTypes = {
  formData: PropTypes.object,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func,
}

export default CollectionInformation
